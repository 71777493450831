<template>
    <h2 class="text-slate-400 text-xl mb-4">Developer Services</h2>
    <div class="flex flex-wrap gap-4 md:gap-0">
        <div class="w-full md:w-1/2 bg-neutral-950 p-6 rounded-md border border-zinc-900">
            <div class="mb-2 flex items-center gap-3 flex-wrap">
                <img src="../assets/botstudio.svg" draggable="false" loading="lazy" alt="The original logo of Bot Studio" width="30" />
                <h2 class="text-2xl font-semibold">Bot Studio - Free, no-code bot maker</h2>
            </div>
            <hr class="my-4 border-zinc-900" />
            <p class="mb-8">Bot Studio is a complimentary, no-code bot builder that enables developers to construct bots for various platforms, including Telegram, WhatsApp, Discord, Slack, and more. It offers a user-friendly drag-and-drop editor interface for creating operational systems that can be deployed as effective robots.</p>
            <a href="https://www.botstudioo.com" class="text-teal-400 hover:underline"><font-awesome-icon icon="arrow-up-right-from-square" class="me-2" />Try Bot Studio for free</a>
        </div>
    </div>
</template>
